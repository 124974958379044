<template>
    <base-form
        ref="form"
        v-model="form"
        :api="api"
        :mapper="mapper"
        :entity-id="entityId"
        :title="title"
        :disabled="disabled"
        @created="created"
        @updated="updated"
        @cancelled="cancelled"
        @deleted="deleted"
    >
        <form-panel>
            <form-two-col-row>
                <template v-slot:col1>
                    <text-input
                        v-model="form.name"
                        v-validate.immediate="'required|max:255|space'"
                        name="name"
                        label="labels.name"
                        :error-messages="errors.collect('name')"
                        :data-vv-as="$t('labels.name')"
                    ></text-input>
                </template>
                <template v-slot:col2>
                    <checkbox-input
                        v-model="form.isActive"
                        label="labels.active"
                        name="isActive"
                    ></checkbox-input>
                </template>
            </form-two-col-row>

            <form-one-col-row>
                <textarea-input
                    v-model="form.expression"
                    v-validate.immediate="'required|max:255|space'"
                    name="expression"
                    label="labels.expression"
                    :error-messages="errors.collect('expression')"
                    :data-vv-as="$t('labels.expression')"
                    :full-width="false"
                ></textarea-input>

                <v-card
                    class="mt-2 pa-2"
                    outlined
                    inner-html.prop="$t('texts.banner_expression_tips')"
                ></v-card>

                <text-input
                    v-model="form.link"
                    v-validate.immediate="'required|max:255|space'"
                    name="link"
                    label="labels.link"
                    :error-messages="errors.collect('link')"
                    :data-vv-as="$t('labels.link')"
                ></text-input>
            </form-one-col-row>
            <form-two-col-row>
                <template v-slot:col1>
                    <image-editor
                        v-model="form"
                        :primary-size="{ width: 600, height: 350 }"
                        :quality="0.9"
                        primary-key="bannerImageMedia"
                        primary-label="labels.banner_image"
                    ></image-editor>
                </template>
                <template v-slot:col2>
                    <textarea-input
                        v-model="form.banner"
                        v-validate.immediate="'required|space'"
                        name="banner"
                        label="labels.banner"
                        :error-messages="errors.collect('banner')"
                        :data-vv-as="$t('labels.banner')"
                        :full-width="false"
                    ></textarea-input>

                    <textarea-input
                        v-model="form.description"
                        v-validate.immediate="'space'"
                        name="description"
                        label="labels.description"
                        :error-messages="errors.collect('description')"
                        :data-vv-as="$t('labels.description')"
                        :full-width="false"
                    ></textarea-input>
                </template>
            </form-two-col-row>
        </form-panel>
    </base-form>
</template>

<script>
import routeType from '@/router/routeType'
import RestapiType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import FormBase from '@/components/mixins/FormBase'
import FormPanel from '@/components/form/FormPanel'
import FormOneColRow from '@/components/form/FormOneColRow'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import TextareaInput from '@/components/form/Inputs/TextareaInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import ImageEditor from '@/components/pages/meals/ImageEditor'

export default {
    components: {
        FormPanel,
        FormOneColRow,
        FormTwoColRow,
        TextInput,
        TextareaInput,
        CheckboxInput,
        ImageEditor,
    },
    mixins: [FormBase],
    data() {
        return {
            api: RestapiType.PROMO_BANNERS,
            mapper: MapperType.PROMO_BANNERS_FORM,
            form: {
                name: null,
                expression: null,
                link: null,
                banner: '',
                isActive: true,
                bannerImageMedia: null,
                description: null,
            },
            entity: 'labels.promo_banner',
            editRoute: routeType.PROMO_BANNERS_EDIT,
            listRoute: routeType.PROMO_BANNERS_LIST,
        }
    },
}
</script>
